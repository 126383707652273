import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-python",
        "parentName": "pre"
      }}>{`import logging

import klarity_integrations
from klarity_integrations.api import klarity_integrations_api
from klarity_integrations.model.estate_records_request_body import EstateRecordsRequestBody
from klarity_integrations.model.estate_records_request_body_records import EstateRecordsRequestBodyRecords

from identity import get_token


def main():
    client_id = "xxxxx"
    client_secret = "****"

    token = get_token(client_id, client_secret)
    if not token:
        return

    # Use \`token\` to communicate with External Integrations API
    configuration = klarity_integrations.Configuration(
        access_token=token,
    )

    # Enter a context with an instance of the API client
    with klarity_integrations.ApiClient(configuration) as api_client:
        # Create an instance of the API class
        api_instance = klarity_integrations_api.KlarityIntegrationsApi(api_client)
        estate_records_request_body = EstateRecordsRequestBody(
            records=[
                EstateRecordsRequestBodyRecords(
                    id="js002",
                    name="Jira Subscription 002",
                    type="jira/subscription/basic",
                    metadata={},
                    tags={
                        "key": "key_example",
                    },
                ),
            ],
        )

        try:
            response = api_instance.v1_estate_records_post(estate_records_request_body=estate_records_request_body)
            logging.info(response)
        except klarity_integrations.ApiException:
            logging.error("Error during upserting estate records")


if __name__ == '__main__':
    main()

`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      