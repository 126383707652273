import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-typescript",
        "parentName": "pre"
      }}>{`import axios from "axios";
import {
  Configuration,
  KlarityIntegrationsApi,
} from "klarity-integrations-sdk-node";
import { exit } from "process";
import { createTokenProvider } from "identity";

const clientId = "xxxxx";
const clientSecret = "********";

// Create a tokenProvider using given \`id\` and \`secret\`.
const tokenProvider = createTokenProvider(clientId, clientSecret);

// Use the token provider to configure API client, allowing to fetch the token live as requests are made.
const client = new KlarityIntegrationsApi(
  new Configuration({ accessToken: tokenProvider }),
  undefined,
  axios
);

async function main() {
  // Use the client to communicate with external integrations API.
  try {
    const { data } = await client.v1EstateRecordsPost({
      records: [
        {
          id: "js002",
          name: "Jira Subscription 002",
          type: "jira/subscription/basic",
          metadata: {},
          tags: { key: "key_example" },
        },
      ],
    });

    console.log(data);
  } catch (error) {
    console.log(error);
    exit(1);
  }
}

main();
`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      