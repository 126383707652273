import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-python",
        "parentName": "pre"
      }}>{`import logging

import requests


def get_token(client_id, client_secret):
    payload = {
        'client_id': client_id,
        'client_secret': client_secret,
        'audience': AUDIENCE,
        'grant_type': "client_credentials",
    }
    r = requests.post(AUTH_URL, json=payload)
    if r.status_code != 200:
        logging.error("Request to auth API failed")
        return ""
    response_body = r.json()
    if not response_body['access_token']:
        logging.error("Empty access token")
        return ""
    return response_body['access_token']


AUTH_URL = "https://identity.nordcloudapp.com/oauth/token"
AUDIENCE = "https://integrations-api.klarity.nordcloudapp.com"

`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      