import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-go",
        "parentName": "pre"
      }}>{`package main
import (
    "example/identity"
    "log"
)

const (
    clientID     = "xxxxx"
    clientSecret = "****"
)

func main() {
    token, err := identity.GetToken(clientID, clientSecret)
    if err != nil {
        log.Fatal(err)
    }

    /*
    Use \`token\` to communicate with External Integrations API
     */
}

`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      