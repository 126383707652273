import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-bash",
        "parentName": "pre"
      }}>{`curl --request POST \\
      --url https://integrations-api.klarity.nordcloudapp.com/v1/estateRecords \\
      --header 'content-type: application/json' \\
      --header 'Authorization: Bearer $token' \\
      --data '{
        "records": [
          {
            "id": "js002",
            "name": "Jira Subscription 002",
            "type": "jira/subscription/basic",
            "metadata": {},
            "tags": {
                "key": "key_example"
            }
          }
        ]
      }'
`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      