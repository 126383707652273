import * as ReactTabs from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../../../../src/docs/usage/style.css";
import ContentGoJwtPackage from "../../../../src/docs/usage/ei_snippets/jwt/go_identity.mdx";
import ContentGoJwtMain from "../../../../src/docs/usage/ei_snippets/jwt/go_main.mdx";
import ContentPythonJwtMain from "../../../../src/docs/usage/ei_snippets/jwt/python_main.mdx";
import ContentPythonJwtPackage from "../../../../src/docs/usage/ei_snippets/jwt/python_identity.mdx";
import ContentTypescriptJwtMain from "../../../../src/docs/usage/ei_snippets/jwt/ts_main.mdx";
import ContentTypescriptJwtPackage from "../../../../src/docs/usage/ei_snippets/jwt/ts_identity.mdx";
import ContentCurlJwt from "../../../../src/docs/usage/ei_snippets/jwt/curl.mdx";
import ContentCurlSDK from "../../../../src/docs/usage/ei_snippets/sdk/curl.mdx";
import ContentGoSDK from "../../../../src/docs/usage/ei_snippets/sdk/go_main.mdx";
import ContentPythonSDK from "../../../../src/docs/usage/ei_snippets/sdk/python_main.mdx";
import ContentTypescriptSDK from "../../../../src/docs/usage/ei_snippets/sdk/ts_main.mdx";
import * as React from 'react';
export default {
  ReactTabs,
  ContentGoJwtPackage,
  ContentGoJwtMain,
  ContentPythonJwtMain,
  ContentPythonJwtPackage,
  ContentTypescriptJwtMain,
  ContentTypescriptJwtPackage,
  ContentCurlJwt,
  ContentCurlSDK,
  ContentGoSDK,
  ContentPythonSDK,
  ContentTypescriptSDK,
  React
};