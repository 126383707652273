import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-go",
        "parentName": "pre"
      }}>{`package identity

import (
    "bytes"
    "encoding/json"
    "errors"
    "net/http"
)

func GetToken(id, secret string) (token string, err error) {
    payload := authPayload{
        ID:        id,
        Secret:    secret,
        Audience:  audience,
        GrantType: "client_credentials",
    }

    payloadJson, err := json.Marshal(payload)
    if err != nil {
        return
    }

    resp, err := http.Post(authUrl, "application/json", bytes.NewBuffer(payloadJson))
    if err != nil {
        return
    }

    var res authResponse

    err = json.NewDecoder(resp.Body).Decode(&res)
    if err != nil {
        return
    }
    if res.Token == "" {
        err = errors.New("empty token")
        return
    }

    return res.Token, nil
}

type authPayload struct {
    ID        string \`json:"client_id"\`
    Secret    string \`json:"client_secret"\`
    Audience  string \`json:"audience"\`
    GrantType string \`json:"grant_type"\`
}

type authResponse struct {
    Token     string \`json:"access_token"\`
    Scope     string \`json:"scope"\`
    ExpiresIn int    \`json:"expires_in"\`
    TokenType string \`json:"token_type"\`
}

const (
    audience = "https://integrations-api.klarity.nordcloudapp.com"
    authUrl  = "https://identity.nordcloudapp.com/oauth/token"
)
`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      