import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-typescript",
        "parentName": "pre"
      }}>{`import axios from "axios";  // we're using \`axios\` as our http client library
import { exit } from "process";

const identityUrl = "https://identity.nordcloudapp.com/oauth/token";
const authAudience = "https://integrations-api.klarity.nordcloudapp.com";

export function createTokenProvider(
  clientId: string,
  clientSecret: string
): () => Promise<string> {
  return async () => {
    try {
      const resp = await axios.post(
        identityUrl,
        {
          client_id: \`\${clientId}\`,
          client_secret: \`\${clientSecret}\`,
          audience: authAudience,
          grant_type: "client_credentials",
        },
        { headers: { "content-type": "application/json" } }
      );

      return resp.data.access_token;
    } catch (error) {
      // exit on error
      console.log("error retrieving token");
      console.log(error);
      exit(1);
    }
  };
}
`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      