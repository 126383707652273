import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-go",
        "parentName": "pre"
      }}>{`package main
import (
    "context"
    "eiclient/identity"
    "fmt"
    "log"
    "net/http"

    "github.com/nordcloud/klarity-integrations-sdk/go/integrations"
)

const (
    clientID     = "xxxxx"
    clientSecret = "****"
)

func main() {
    token, err := identity.GetToken(clientID, clientSecret)
    if err != nil {
        log.Fatal(err)
    }

    client := integrations.NewAPIClient(integrations.NewConfiguration())
    authCtx := context.WithValue(context.Background(), integrations.ContextAccessToken, token)

    // Use \`token\` to communicate with External Integrations API

    // eg. add new estate records:

    var records []integrations.EstateRecordsRequestBodyRecords
    record := integrations.NewEstateRecordsRequestBodyRecords("js002", "jira/subscription/basic")
    record.SetName("Jira Subscription 002")
    record.SetTags(map[string]string{
        "key": "key_example",
    })
    records = append(records, *record)

    req := client.KlarityIntegrationsApi.
        V1EstateRecordsPost(authCtx).
        EstateRecordsRequestBody(integrations.EstateRecordsRequestBody{
            Records: &records,
        })

    resp, httpResp, err := req.Execute()
    if err != nil {
        log.Fatal(err)
    }
    if httpResp.StatusCode != http.StatusAccepted {
        log.Fatal("Invalid status: ", httpResp.StatusCode)
    }

    fmt.Println(*resp.Message)
}

`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      