import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-typescript",
        "parentName": "pre"
      }}>{`import { createTokenProvider } from "example/identity";

const clientId = "xxxxx";
const clientSecret = "********";

// Create a tokenProvider using given \`id\` and \`secret\`.
const tokenProvider = createTokenProvider(clientId, clientSecret);

async function main() {
  // Use the token provider to retrieve token.
  const token = await tokenProvider();

  // Use \`token\` to communicate with external integration API.
}

main();
`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      