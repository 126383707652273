export default {
  colors: {
    primary: "#161632",
    background: "#fff",
    sidebar: {
      background: "#ffffff",
      link: "#999",
      heading: "#aaa",
      linkActive: "#161632",
      itemActive: "rgba(0,0,0,.05);",
      footer: "#161632",
    },
  },
};
