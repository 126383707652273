import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-bash",
        "parentName": "pre"
      }}>{`curl --request POST \\
      --url https://identity.nordcloudapp.com/oauth/token \\
      --header 'content-type: application/json' \\
      --data '{"client_id":"$id","client_secret":"$secret","audience":"https://integrations-api.klarity.nordcloudapp.com","grant_type":"client_credentials"}'

`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      