import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code {...{
        "className": "language-python",
        "parentName": "pre"
      }}>{`from identity import get_token


def main():
    client_id = "xxxxx"
    client_secret = "****"

    token = get_token(client_id, client_secret)
    if not token:
        return

    # Use \`token\` to communicate with External Integrations API


if __name__ == '__main__':
    main()

`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      