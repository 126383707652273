import React from 'react';
import './style.css'

export const Button = ({link, name}) => {
    return (
        <div style={{marginBottom: '20px'}}>   
            <a 
            href={link}
            download="binary">
            <button type="button" className='download-button'>{name}</button>
            </a>
        </div>
     
    );
  };
  